import printJS from 'print-js';
import { degrees, PDFDocument } from 'pdf-lib';
import JsBarcode from 'jsbarcode';
import { API_ETIQUETAS } from '../../views/Main/components/shared/services/etiquetas';

/**
 * Convierte un ArrayBuffer en una cadena base64.
 *
 * @param {ArrayBuffer} buffer - El ArrayBuffer a convertir.
 * @returns {string} - La cadena base64 resultante.
 */
const arrayBufferToBase64 = (buffer) => {
  const bytes = new Uint8Array(buffer);
  let binary = '';
  for (let i = 0; i < bytes.byteLength; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
};

/**
 * Limpia una cadena de texto en formato base64 eliminando cualquier carácter de espacio en blanco.
 *
 * @param {string} base64 - La cadena de texto en formato base64 a limpiar.
 * @returns {string} - La cadena de texto en formato base64 sin caracteres de espacio en blanco.
 */
const cleanBase64 = (base64) => {
  if (base64.startsWith('data:')) {
    base64 = base64.split(',')[1];
  }
  return base64.replace(/\s/g, '');
};

/**
 * Agrega un código de barras a un archivo PDF.
 *
 * @param {string} base64Pdf - La cadena base64 del archivo PDF.
 * @param {string} barcodeText - El texto del código de barras.
 * @returns {Promise<string>} - Una promesa que se resuelve con la cadena base64 del archivo PDF modificado.
 */
const addBarcodeToPDF = async (base64Pdf, pedido) => {
  const barcodeText = pedido.numero_presupuesto;
  const plataforma = pedido.plataforma_id;
  const piezasIds = pedido.piezas.map((pieza) => '#' + pieza.id_referencia_plataforma).join(', ')
  // Create a new PDFDocument
  let pdfDoc;
  // convert base64 to Array Buffer
  const pdfBytes = Uint8Array.from(atob(base64Pdf), c => c.charCodeAt(0))
  const canvas = document.createElement('canvas');
  JsBarcode(canvas, barcodeText, {
    format: 'CODE128',
    width: 2,
    height: 70,
  });
  const barcodeImage = canvas.toDataURL('image/png');
  const barcodeImageBytes = await fetch(barcodeImage).then((res) =>
    res.arrayBuffer()
  );
  
  if(plataforma === 3) {
    pdfDoc = await getRfEtiquetaPdf(pdfBytes, barcodeImageBytes, piezasIds);
  } else {
    pdfDoc = await getEtiquetaPdf(pdfBytes, barcodeImageBytes, piezasIds);
  }

  const modifiedPdfBytes = await pdfDoc.save();
  return arrayBufferToBase64(modifiedPdfBytes);
};

const getEtiquetaPdf = async(pdfBytes, barcodeImageBytes, piezasIds) => {
  const A5_WIDTH = 148 * 2.83465; // A5 width in points
  const A5_HEIGHT = 210 * 2.83465; // A5 height in points
  const pdfDoc = await PDFDocument.create();
  const pdfEtiqueta = await PDFDocument.load(pdfBytes);
  const etiquetaPage = pdfEtiqueta.getPages()[0];
  const { width, height } = etiquetaPage.getSize();
  const newPage = pdfDoc.addPage([A5_WIDTH, A5_HEIGHT]);
  const [etiquetaEnvio] = await pdfDoc.embedPages([etiquetaPage]);

  // BARCODE 
  const barcodeImageEmbed = await pdfDoc.embedPng(barcodeImageBytes);
  const barcodeX = 5;
  const barcodeY = 10;
  const barcodeWidth = 100;
  const barcodeHeight = 50;
  
  if(width > A5_WIDTH) {
    newPage.drawPage(etiquetaEnvio, {
      x: height + 15,
      y: 0,
      rotate: degrees(90)
    });

    newPage.drawImage(barcodeImageEmbed, {
      x: A5_WIDTH - (barcodeWidth / 2) + barcodeX,
      y: barcodeY,
      width: barcodeWidth,
      height: barcodeHeight,
      rotate: degrees(90)
    });

    newPage.drawText(piezasIds, {
      x: A5_WIDTH - (barcodeWidth / 2) + barcodeX + 25,
      y: barcodeY,
      width: barcodeWidth,
      height: barcodeHeight,
      rotate: degrees(90),
      size: 10
    });
  } else {
    const scaledWidth = width * 1.2;
    const scaledHeight = height * 1.2;

    const x = 10;
    const y = barcodeHeight + 25;

    newPage.drawPage(etiquetaEnvio, {
      x, 
      y,
      width: scaledWidth,
      height: scaledHeight
    });

    newPage.drawImage(barcodeImageEmbed, {
      x: A5_WIDTH - (barcodeWidth + 25),
      y: barcodeY + 10,
      width: barcodeWidth,
      height: barcodeHeight,
    });

    newPage.drawText(piezasIds, {
      x: 10,
      y: barcodeY,
      width: barcodeWidth,
      height: barcodeHeight,
      size: 10
    });
  }

  return pdfDoc;
}

const getRfEtiquetaPdf = async(pdfBytes, barcodeImageBytes, piezasIds) => {
  // Create a new PDFDocument
  const pdfDoc = await PDFDocument.create();
  const pdfEtiqueta = await PDFDocument.load(pdfBytes);
  const etiquetaPage = pdfEtiqueta.getPages()[0];
  const A5_WIDTH = 148 * 2.83465; // A5 width in points
  const A5_HEIGHT =  210 * 2.83465; // A5 height in points
  // Embed the second page of the constitution and clip the preamble
  const newPage = pdfDoc.addPage([A5_WIDTH, A5_HEIGHT]);
  const etiquetaEnvio = await pdfDoc.embedPage(etiquetaPage, {
    left: 15,
    bottom: 545,
    right: 700,
    top: 855,
  });

  newPage.drawPage(etiquetaEnvio, {
    x: etiquetaEnvio.height - 20,
    y: 10,
    rotate: degrees(90)
  });

  // ADD BARCODE 
  const barcodeImageEmbed = await pdfDoc.embedPng(barcodeImageBytes);
  const barcodeX = 5;
  const barcodeY = 25;
  const barcodeWidth = 100;
  const barcodeHeight = 50;

  newPage.drawImage(barcodeImageEmbed, {
    x: A5_WIDTH - (barcodeWidth / 2) + barcodeX,
    y: barcodeY,
    width: barcodeWidth,
    height: barcodeHeight,
    rotate: degrees(90)
  });

  newPage.drawText(piezasIds, {
    x: A5_WIDTH - (barcodeWidth / 2) + barcodeX + 25,
    y: barcodeY,
    width: barcodeWidth,
    height: barcodeHeight,
    rotate: degrees(90),
    size: 10
  });
  
  
  return pdfDoc
}

export const getEtiquetaPieza = async(pieza) => {
  const canvas = document.createElement('canvas');
  JsBarcode(canvas, pieza.seinto_id, {
    format: 'CODE128',
    width: 2,
    height: 70,
  });
  const barcodeImage = canvas.toDataURL('image/png');
  const barcodeImageBytes = await fetch(barcodeImage).then((res) =>
    res.arrayBuffer()
  );

  const ETIQUETA_WIDTH = 198.425;         // Etiqueta width in points
  const ETIQUETA_HEIGHT = 85.0394;        // Etiqueta height in points
  const pdfDoc = await PDFDocument.create();
  const newPage = pdfDoc.addPage([ETIQUETA_WIDTH, ETIQUETA_HEIGHT]);

  // BARCODE 
  const barcodeImageEmbed = await pdfDoc.embedPng(barcodeImageBytes);
  const barcodeWidth = 100;
  const barcodeHeight = 50;

  newPage.drawImage(barcodeImageEmbed, {
    x: 45,
    y: 25,
    width: barcodeWidth,
    height: barcodeHeight,
  });

  newPage.drawText(pieza.nombre, {
    x: 10,
    y: 10,
    width: barcodeWidth,
    height: 15,
    size: 8
  });

  const modifiedPdfBytes = await pdfDoc.save();
  printMergedPDF([`data:application/pdf;base64,${arrayBufferToBase64(modifiedPdfBytes)}`])
}

/**
 * Obtiene la etiqueta en formato PDF y le agrega un código de barras.
 *
 * @param {string} envio_id - El ID del envío.
 * @param {number} plataforma - La plataforma.
 * @param {string} pedido_id - El ID del pedido.
 * @returns {Promise<string|null>} - La etiqueta en formato PDF en base64 o null si no se pudo obtener.
 */
export const getEtiqueta = async (envio_id, pedido) => {
  if (!envio_id && !pedido) {
    return null;
  }

  try {
    let response;
    if (pedido.plataforma_id === 3 && !pedido.piezas.find(p => p.etiqueta_propia && p.etiqueta_file !== null)) {
      response = await API_ETIQUETAS.getPdfEtiqueta('email', pedido.id);
    } else {
      response = await API_ETIQUETAS.getPdfEtiqueta('pedido', pedido.id, envio_id);
    }

    if (response.success) {
      const base64Pdf = response.etiqueta;
      return `data:application/pdf;base64,${await addBarcodeToPDF(
        base64Pdf,
        pedido
      )}`;
    } else return null;
  } catch (error) {
    console.error('Error in getEtiqueta:', error);
    return null;
  }
};

/**
 * Descarga un archivo PDF a partir de una cadena en base64.
 *
 * @param {string} base64 - La cadena en base64 que representa el archivo PDF.
 * @param {string} filename - El nombre del archivo PDF a descargar.
 */
export const downloadPDF = (base64, filename) => {
  const linkSource = `data:application/pdf;base64,${base64}`;
  const downloadLink = document.createElement('a');
  const fileName = filename;

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

/**
 * Combina varios archivos PDF en uno solo.
 *
 * @param {string[]} pdfBase64s - Un array de strings que contienen los archivos PDF en formato base64.
 * @returns {Promise<string>} - Una promesa que se resuelve con el archivo PDF combinado en formato base64.
 */
const mergePDFs = async (pdfBase64s) => {
  const mergedPdf = await PDFDocument.create();
  for (const base64 of pdfBase64s) {
    const pdfData = await fetch(
      `data:application/pdf;base64,${cleanBase64(base64)}`
    ).then((res) => res.arrayBuffer());
    const pdf = await PDFDocument.load(pdfData);
    const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
    copiedPages.forEach((page) => {
      mergedPdf.addPage(page);
    });
  }
  const mergedPdfData = await mergedPdf.saveAsBase64({ dataUri: true });
  return mergedPdfData;
};

/**
 * Imprime un documento PDF fusionado.
 *
 * @param {string[]} pdfBase64s - Un array de cadenas PDF codificadas en base64.
 * @returns {Promise<void>} - Una promesa que se resuelve cuando el PDF se imprime correctamente.
 */
export const printMergedPDF = async (pdfBase64s) => {
  try {
    const mergedPdfBase64 = await mergePDFs(pdfBase64s);
    const cleanedBase64 = cleanBase64(mergedPdfBase64);
    printJS({ printable: cleanedBase64, type: 'pdf', base64: true });
  } catch (error) {
    console.error('Error merging and printing PDF:', error);
  }
};