import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const Filter = ({
    infoByBastidor,
    onSearch
}) => {
  const [bastidor, setBastidor] = useState('');
  const [idPiezaDesp, setIdPiezaDesp] = useState('');

  const handleSetBastidor = (e) => {
    setBastidor(e.target.value)
    if (idPiezaDesp) return setIdPiezaDesp('')
  }
  
  const handleSetIdPiezaDesp = (e) => {
    setIdPiezaDesp(e.target.value)
    if (bastidor) return setBastidor('')
  }

  return (
    <div className='w-100 d-flex align-items-center my-4'>
        <div className="me-3">
            <div className="position-relative">
                <input 
                    type="text" 
                    className="form-control" 
                    value={bastidor} 
                    id="vin" 
                    placeholder="Número de VIN"
                    onChange={handleSetBastidor}
                    onKeyDown={(e) => e.key === 'Enter' && onSearch('bastidor', bastidor)}
                />
                <FontAwesomeIcon 
                    icon={faSearch} 
                    color="#215732" 
                    className="icon" 
                    style={{cursor: 'pointer'}} 
                    onClick={() => onSearch('bastidor', bastidor)}
                />
            </div>
        </div>

        <div className="me-3">
            <div className="position-relative">
                <input 
                    type="text" 
                    className="form-control" 
                    value={idPiezaDesp} 
                    id="id" 
                    placeholder="ID de plataforma"
                    onChange={handleSetIdPiezaDesp}
                    onKeyDown={(e) => e.key === 'Enter' && onSearch('idPiezaDesp', idPiezaDesp)}
                />
                <FontAwesomeIcon 
                    icon={faSearch} 
                    color="#215732" 
                    className="icon" 
                    style={{cursor: 'pointer'}} 
                    onClick={() => onSearch('idPiezaDesp', idPiezaDesp)}
                />
            </div>
        </div>

        { (infoByBastidor) &&
            <>
            <div className='me-3'>
                <p>Marca: <span className='fw-bold'>{infoByBastidor.marca}</span></p>
            </div>

            <div className='me-3'>
                <p>Modelo: <span className='fw-bold'>{infoByBastidor.modelo}</span></p>
            </div>

            <div className='me-3'>
                <p>Matrícula: <span className='fw-bold'>{infoByBastidor.matricula}</span></p>
            </div>

            <div className='me-3'>
                <p>KMS: <span className='fw-bold'>{infoByBastidor.kms} KM</span></p>
            </div>

            <div className='me-3'>
                <p>Bastidor: <span className='fw-bold'>{infoByBastidor.bastidor}</span></p>
            </div>
            </>
        }
    </div>
  );
};

export default Filter;
