import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

export default function PiezasSelector({
    pedido,
    onSubmitCancelarPiezas
}) {
    const { register, handleSubmit } = useForm({
        defaultValues: {
            piezas: []
        }
    });

    const handleSubmitCancelarPiezas = async(data, e) => {
        e.preventDefault();
        
        if(data.piezas?.length === 0) {
            toast.warning('Tienes que seleccionar al menos una pieza!');
            return;
        }

        onSubmitCancelarPiezas(data);
    }
    
    return (
        <div className="w-100 d-flex flex-column">
            <form className="form align-items-center mb-4" onSubmit={handleSubmit(handleSubmitCancelarPiezas)}>
                <div className="w-100 d-flex flex-column flex-grow-1">
                    { pedido.piezas.map(pieza => {
                        return (
                            <div className="form-group flex-row align-items-center" 
                                key={`cancelar-pieza-${pieza.id}`}
                            >
                                <input type="checkbox"
                                    name={`piezas[]`}
                                    {...register(`piezas[]`)}
                                    value={pieza.id}
                                    disabled={pieza.cancelada}  />
                                <label htmlFor="etiqueta" className="m-0 ms-3">#{pieza.id_referencia_plataforma} {pieza.nombre}</label>
                                {pieza.cancelada ? <small className="fw-bold text-danger ms-2">CANCELADA</small> : ''}
                            </div>
                        )
                    })}
                    
                </div>

                <div className="d-flex align-items-center align-self-end ms-3 mt-4">
                    <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                </div>
            </form>
        </div>
    )
}