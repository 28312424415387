import { Schedule } from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react';
import 'react-tabs/style/react-tabs.css';
import PedidosTable from './PedidosTable';
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboard } from '../../../../redux/features/dashboard';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';
import AsignarPedidos from './AsignarPedidos';
import InfoModal from '../shared/components/modals/Info';
import './dashboard.scss';

export default function Dashboard() {
  const dispatch = useDispatch();
  const dashboard = useSelector(state => {
      return state.dashboard.entity
  });
  const loading = useSelector(state => {
    return state.loading
  })
  const [currentPage, setPage] = useState(1);
  const currentUser = JSON.parse(localStorage.getItem('user'));
  const initialActiveTab = currentUser.rol_id === 2 ? 'individual' : 'general';
  const tab = useRef(initialActiveTab);
  const [lectorModalOpen, setLectorModalOpen] = useState(false);
  const filterStatus = useRef(currentUser.rol_id === 2 ? 3 : 1);
  const filterValues = useRef({
    id: '',
    numero_presupuesto: '',
    fecha: '',
    reload: 0,
  });

  useEffect(() => {
    fetchPedidos();
  }, []);

  const fetchPedidos = async(page = 1, filters) => {
    if(tab.current === 'general') {
      dispatch(fetchDashboard(page, filterStatus.current, null, filters))
    } else if(tab.current === 'individual') {
      dispatch(fetchDashboard(page, 3, currentUser.id, filters))
    } else {
      dispatch(fetchDashboard(page, filterStatus.current, (currentUser.rol_id === 2 ? currentUser.id : null), filters))
    }
  }

  const handleChangeStatus = (statusId) => {
    filterStatus.current = statusId;
    filterValues.current = {...filterValues.current, reload: 0};
    handleChangePage(1);
  }

  const handleFilterChange = (page, filter) => {
    if(filter === null) {
      filterValues.current = {
        id: '',
        numero_presupuesto: '',
        fecha: '',
        reload: 0
      }
    } else {
      filterValues.current = {...filterValues.current, ...filter, reload: 0}
    }

    handleChangePage(page);
  }

  const handleChangePage = (newPage, reload = false) => {
    setPage(newPage);

    if(!reload) filterValues.current = {...filterValues.current, reload: 0};
    fetchPedidos(newPage, filterValues.current);
  };

  const handleChangeTab = (e, newTab) => {
    setPage(1);
    tab.current = newTab;
    filterValues.current = {...filterValues.current, reload: 0}

    if(newTab === 'general') {
      filterStatus.current = 1;
      dispatch(fetchDashboard(1, 1, null, filterValues.current))
    } else if(newTab === 'individual') {
      dispatch(fetchDashboard(1, 3, currentUser.id, filterValues.current))
    } else {
      filterStatus.current = 4;
      dispatch(fetchDashboard(1, 4, (currentUser.rol_id === 2 ? currentUser.id : null), filterValues.current))
    }
  }

  const handleManualRefresh = () => {
    filterValues.current = {...filterValues.current, reload: 1};
    handleChangePage(1, true)
  };

  const handleOpenLectorModal = () => { setLectorModalOpen(true); };
  const handleCloseLectorModal = () => { setLectorModalOpen(false); };

  const formatTextLastUpdated = () => {
    return dashboard?.last_update
      ? moment(dashboard?.last_update).tz('Europe/Madrid').format('HH:mm:ss - DD/MM')
      : 'N/A';
  };

  return (
    <div className='content'>
      <h1>Dashboard</h1>

      <div className="d-flex flex-wrap flex-lg-nowrap align-items-stretch justify-content-between my-3">
        <div className='flex-grow-1 d-flex flex-wrap flex-lg-nowrap align-items-center pe-0 pe-md-3 metrics'>
          { (dashboard && dashboard.metricas) &&
            <>
              <div className='flex-grow-1 box text-center'>
                <p>Pendientes de descarga</p>
                <h1>{dashboard.metricas.pendientesDescarga}</h1>
              </div>

              <div className='flex-grow-1 box text-center'>
                <p>Descargados</p>
                <h1>{dashboard.metricas.descargados}</h1>
              </div>

              <div className='flex-grow-1 box text-center mt-3 mt-lg-0'>
                <p>Asignados</p>
                <h1>{dashboard.metricas.asignados}</h1>
              </div>

              <div className='flex-grow-1 box text-center mt-3 mt-lg-0'>
                <p>Preparados para recoger</p>
                <h1>{dashboard.metricas.preparados}</h1>
              </div>
            </>
          }
        </div>

        <div className='flex-grow-1 flex-lg-grow-0 d-flex flex-column align-items-stretch align-items-md-start justify-content-center mt-4 mt-lg-0'>
          <div>
            { (currentUser.rol_id !== 1) &&
              <button
                className="btn btn-primary mb-4"
                onClick={handleOpenLectorModal}
              >
                Escanear pedidos
              </button>
            }
          </div>

          <div className='d-flex flex-column flex-md-row align-items-stretch align-items-md-center'>
            <button
              className="btn btn-primary me-2 mb-3 mb-md-0"
              style={{ width: '100%' }}
              onClick={handleManualRefresh}
            >
              Refrescar pedidos
            </button>
            <div className="d-flex flex-row align-items-center justify-content-center">
              <Schedule></Schedule>
              <span className="bg-secondary ms-2" style={{whiteSpace: 'nowrap'}}>{formatTextLastUpdated()}</span>
            </div>
          </div>
        </div>
      </div>

      <div className='w-100'>
        <TabContext value={tab.current}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChangeTab} aria-label="Dashboard">
              { (currentUser.rol_id !== 2) ?
                <Tab label={
                    <div className='d-flex align-items-center'>
                      <span>General</span>
                      { tab.current === 'general' && 
                        <span className="badge ms-2">
                          { (loading == true) ?
                            <FontAwesomeIcon icon={faSpinner} size="1x" spin />
                            :
                            dashboard?.pedidos?.count
                          }
                        </span> 
                      }
                    </div>
                  }
                  value="general" />
               : null
              }
              { (currentUser.rol_id === 2) ?
                <Tab label={
                    <div className='d-flex align-items-center'>
                      <span>Individual</span>
                      { tab.current === 'individual' && 
                        <span className="badge ms-2">
                          { (loading == true) ?
                            <FontAwesomeIcon icon={faSpinner} size="1x" spin />
                            :
                            dashboard?.pedidos?.count
                          }
                        </span>
                      }
                    </div>
                  }
                  value="individual" />
                : null
              }
              <Tab label={
                <div className='d-flex align-items-center'>
                  <span>Histórico de pedidos</span>
                  { tab.current === 'historico' && 
                    <span className="badge ms-2">
                      { (loading == true) ?
                        <FontAwesomeIcon icon={faSpinner} size="1x" spin />
                        :
                        dashboard?.pedidos?.count
                      }
                    </span>
                  }
                </div>
              } 
              value="historico" />
          </TabList>
          </Box>
          <TabPanel value="general">
            <PedidosTable 
              id={'general'}
              page={currentPage}
              filterStatus={filterStatus.current}
              filterValues={filterValues.current}
              lastUpdated={dashboard?.last_update}
              pedidos={dashboard?.pedidos?.rows}
              userId={currentUser.id}
              isAdmin={currentUser.rol_id === 1}
              onPageChange={handleChangePage}
              onFilterChange={handleFilterChange}
              onStatusChange={handleChangeStatus}
            />
          </TabPanel>
          <TabPanel value="individual">
            <PedidosTable
              id={'individual'}
              page={currentPage}
              filterValues={filterValues.current}
              lastUpdated={dashboard?.last_update}
              pedidos={dashboard?.pedidos?.rows}
              userId={currentUser.id}
              isAdmin={currentUser.rol_id === 1}
              onPageChange={handleChangePage}
              onFilterChange={handleFilterChange}
            />
          </TabPanel>
          <TabPanel value="historico">
            <PedidosTable 
              id={'historico'}
              page={currentPage}
              filterValues={filterValues.current}
              lastUpdated={dashboard?.last_update}
              pedidos={dashboard?.pedidos?.rows}
              isAdmin={currentUser.rol_id === 1}
              onPageChange={handleChangePage}
              onFilterChange={handleFilterChange}
              onStatusChange={handleChangeStatus}
            />
          </TabPanel>
        </TabContext>
      </div>
      
      { (lectorModalOpen) &&
        <InfoModal
          title={"Asignación de pedidos"}
          content={
            <AsignarPedidos 
              onAssign={fetchPedidos}
            />
          }
          state={lectorModalOpen}
          width={"md"}
          onClose={handleCloseLectorModal}
        />
      }
    </div>
  )
}
